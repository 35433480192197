import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {
  AddTicketDesktopComponent, AddTicketsViewModel,
  CareCircleManipulationDesktopComponent,
  CareCircleManipulationViewModel
} from "@daisy/uikit";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import {LockModalComponent} from "../shared/components/lock-modal/lock-modal.component";
import {
  AddTicketCategoryAction, LoadFirstCategoryTicketAction,
  LoadTicketCategoriesAction,
  PatientFacadeService,
  RemoveTicketCategoryAction
} from "@daisy2/core";
import {ToastrService} from "ngx-toastr";


@Injectable({
  providedIn: 'root',
})
export class PatientUIKitService {

  constructor(public modalService: NgbModal,
              private careCircleManipulationViewModel: CareCircleManipulationViewModel,
              private patientFacedService:PatientFacadeService,
              private router:Router,
              private notification:ToastrService) {

  }


  public signalEvent = new BehaviorSubject<number>(0);

  public loadCategorySuccess() {
    console.log('load category success');
    this.signalEvent.next(1);
  }

  public openSignOutDialog() {
    console.log('dfdf1')
  }

  public openAboutDialog() {
    console.log('dfdf1222')
  }

  public openPrivacyDialog() {
    console.log('dfdf1333n')
  }

  public openAddScheduleDesktopDialog() {

  }

  public openAddPillBoxDesktopDialog() {

  }

  public openAddCareDesktopDialog() {
    this.modalService.open(CareCircleManipulationDesktopComponent, {centered: true, scrollable: true}).result.then(
      (result) => {
        //this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  public openEditCareDesktopDialog(careId: string) {
    console.log('aaaaaa');
    if (careId !== '') {

      this.modalService.open(CareCircleManipulationDesktopComponent, {centered: true, scrollable: true}).result.then(
        (result) => {
          //this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );
      this.careCircleManipulationViewModel.activeEditMode(careId);
    }
  }

  public openAddTicketDesktopDialog() {
    // this.patientFacedService.dispatch(new RemoveTicketCategoryAction('DME'))
    this.modalService.open(AddTicketDesktopComponent, {centered: true}).dismissed.subscribe(result=>{
      console.log('res',result)
      if (result == 0){
        this.patientFacedService.dispatch(new LoadFirstCategoryTicketAction([]))

      }
    })
  }

  public ClickedNavigateToNotificationPage(pageName: string) {
    const pageDictionary:any = {
      Ticket: '/panel/requests',
      Request: '/panel/requests',
      Schedule: '/panel/schedule',
      PillBox: '/panel/pillbox',
      HowDoYouFeel: '/panel/feeling/symptoms'
    }
    let route = pageDictionary[pageName]
    this.router.navigate([route])
    console.log('up page', pageName)
  }

  showToast(message:any,type:any){
    if (type == 'error'){
      this.notification.error(message)
    }else if (type == 'success'){
      this.notification.success(message)
    }else if (type == 'warning'){
      this.notification.warning(message)
    }else if (type == 'info'){
      this.notification.info(message)
    }
  }



}
