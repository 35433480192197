import {Injectable} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {
  AboutUsDialogDesktopComponent,
  AboutUsMobileDialogComponent,
  AddRequestPopUpComponent, AddTicketsViewModel, CareCircleManipulationDesktopComponent,
  DaisySuccessDialogComponent,
  DietManipulationComponent,
  ExitDesktopDialogComponent, HabitManipulationDesktopComponent,
  HabitManipulationMobileComponent,
  PillboxManipulationDesktopComponent,
  ReportAttachmentsViewModel,
  ReportDownloadHistoryComponent,
  ScheduleManipulationDesktopComponent,

} from "@daisy/uikit";
import {Router} from "@angular/router";
import Utilities from "../shared/utilities";
import {ToastrService} from "ngx-toastr";
import {Location} from '@angular/common';
import {LockModalComponent} from "../shared/components/lock-modal/lock-modal.component";
import {
  RequestSymptomTriageModalComponent
} from "../tickets/request-symptom-triage-modal/request-symptom-triage-modal.component";
import {firstValueFrom} from "rxjs";
import {
  CreateRequestSuccessModalComponent
} from "../request/modal/create-request-success-modal/create-request-success-modal.component";
import {ModalController} from "@ionic/angular";

@Injectable({
  providedIn: 'root',
})
export class PatientUIService {

  constructor(private modalService: NgbModal,
              private toastr: ToastrService,
              public _location: Location,
              private router: Router,
              public modalCtrl: ModalController,
  ) {
  }

  public OpenAddHabitDialog(content: any) {
    this.modalService.open(HabitManipulationMobileComponent).result.then(
      (result) => {
        //this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  public ClickedAddHabitDesktopEventHandler(content: any) {
    this.modalService.open(HabitManipulationDesktopComponent).result.then(
      (result) => {
        //this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  public openDietDesktopDialog(content: any) {
    this.modalService.open(DietManipulationComponent).result.then(
      (result) => {
        //this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  public vitalSuccessMessage(message: any) {
    let modalRef = this.modalService.open(DaisySuccessDialogComponent, {centered: true});
    if (message.length == 0) {
      modalRef.componentInstance.message = "Your Vitals has been added.";

    } else {
      modalRef.componentInstance.message = message;
    }


  }

  public scheduleSuccessMessage() {
    this.modalService.dismissAll()
    let modalRef = this.modalService.open(DaisySuccessDialogComponent, {centered: true});
    modalRef.componentInstance.message = "Your Schedule has been successfully Added.";
    this.router.navigate(['/panel/schedule']);

  }

  public pillBoxSuccessMessage() {
    this.modalService.dismissAll()
    let modalRef = this.modalService.open(DaisySuccessDialogComponent, {centered: true});
    modalRef.componentInstance.message = "Your PillBox has been successfully Added.";
    this.router.navigate(['/panel/pillbox']);
  }

  public ticketSuccessMessage() {
    let modalRef = this.modalService.open(AddRequestPopUpComponent, {centered: true});
    modalRef.componentInstance.addRequestURL = "/panel/tickets/new";
    modalRef.componentInstance.finishURL = "/panel/tickets";
  }

  public inviationRelativeSuccessMessage() {
    this.modalService.dismissAll()
    let modalRef = this.modalService.open(DaisySuccessDialogComponent, {centered: true});
    modalRef.componentInstance.message = "Your Care has been successfully Added.";
    if (Utilities.screen_manager()) {
      //this code added for determine care added in connection or add in setting
      this._location.back()
    }

  }


  public SingOutSuccess() {
    this.router.navigate(['/']);
  }

  public signOut() {
    let modalRef = this.modalService.open(ExitDesktopDialogComponent, {centered: true});

  }

  public about() {
    let modalRef = this.modalService.open(AboutUsMobileDialogComponent, {centered: true});

  }

  public aboutDesktop() {
    let modalRef = this.modalService.open(AboutUsDialogDesktopComponent, {centered: true});

  }

  // public OpenAddScheduleDialog(){
  //   this.modalService.open(ScheduleManipulationDesktopComponent).result.then(
  //     (result) => {
  //       //this.closeResult = `Closed with: ${result}`;
  //     },
  //     (reason) => {
  //       // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //     },
  //   );
  // }
  public createChatRoomSuccess() {
    this.router.navigate(['/panel/connections/textchat'], {queryParams: {q: '8ff3fcf9-c3d0-4973-8724-4eec93220d6b'}});

  }

  public loadSuccessQuestion() {
    console.log('quetion >>>>>>>>>>>>>>');
  }

  public openAddScheduleDesktopDialog() {
    this.modalService.open(ScheduleManipulationDesktopComponent, {scrollable: true}).result.then(
      (result) => {
        //this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  public openAddPillBoxDesktopDialog() {
    this.modalService.open(PillboxManipulationDesktopComponent, {scrollable: true}).result.then(
      (result) => {
        //this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  public openAddCareDesktopDialog() {
    this.modalService.open(ExitDesktopDialogComponent, {centered: true}).result.then(
      (result) => {
        //this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }


  public signOutDesktop() {
    this.modalService.open(ExitDesktopDialogComponent, {centered: true}).result.then(
      (result) => {
        //this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  public searchSuccessResult() {
    console.log('search result recived...........');
    if (Utilities.screen_manager()) {
      this.router.navigate(['/panel/report/search']);
    } else {
      this.router.navigate(['/panel/report/search']);
    }
  }

  public removeRelativeSuccessMessage() {
    this.router.navigate(['/panel/setting/cares']);
  }

  public updateRelativeSuccessMessage() {
    this.modalService.dismissAll()
    let modalRef = this.modalService.open(DaisySuccessDialogComponent, {centered: true});
    modalRef.componentInstance.message = "Your Care has been successfully Updated.";
    if (Utilities.screen_manager()) {
      //this code added for determine care added in connection or add in setting
      this._location.back()
    }
  }

  public openImagesAttachmentDialog() {
    let modalRef = this.modalService.open(ReportDownloadHistoryComponent, {centered: true});

  }

  public openFilesAttachmentDialog() {
    let modalRef = this.modalService.open(ReportDownloadHistoryComponent, {centered: true});

  }

  public inviationRelativeFailureMessage(message: string) {
    this.toastr.error(message, 'Failed');

  }

  public urgentCallSuccess(payload: any) {
    console.log('payload',payload)
    let urgentCareTeam = payload.patientCares.items.filter((item:any)=>item.priority>=1)

    console.log('care list',urgentCareTeam)
    if (urgentCareTeam.length > 0){
      urgentCareTeam = urgentCareTeam.map((item:any)=>{
        return {
          id:item.id,
          firstName:item.firstName,
          lastName: item.lastName,
          priority:item.priority
        }
      })


      this.router.navigate(['panel', 'connections', 'videochat'], {
        queryParams: {
          type:'urgent',
          cares:JSON.stringify(urgentCareTeam)
        }
      })
    }else {
      this.toastr.warning('You have not set any care for urgent call!')
    }


  }

  ClickedUnLockPanelEventHandler(){
    const modal = this.modalService.open(LockModalComponent,{centered:true})
  }


  public goToAddNewTicketPage(){
    this.router.navigate(['/panel','tickets','new'])
  }

  public openTicketSymptomTriagePopup(data:any){
    this.modalService.dismissAll()
    let modalRef = this.modalService.open(RequestSymptomTriageModalComponent, {centered: true})
      .dismissed.subscribe(result=>{
        if (result == 0){
          this.router.navigate(['/panel','tickets'])
        }else {
          console.log('datqa',data)
          let category = JSON.stringify(data.category)

          this.router.navigate(['/panel','feeling', 'symptoms'], {queryParams: {ticketId: result,category:category}})
        }
      })

  }

  public openRequestSymptomTriagePopup(data:any){
    this.modalService.dismissAll()
    let modalRef = this.modalService.open(RequestSymptomTriageModalComponent, {centered: true})
      .dismissed.subscribe(result=>{
        if (result == 0){
          this.router.navigate(['/panel','requests'])
        }else {
          console.log('datqa',data)
          let category = JSON.stringify(data.category)

          this.router.navigate(['/panel','feeling', 'symptoms'], {queryParams: {request:'true',category:category}})
        }
      })
  }

  public openDialogCreateRequestSuccessfully(){
    this.modalService.dismissAll()
    const modalRef = this.modalService.open(CreateRequestSuccessModalComponent,{centered:true})
    modalRef.componentInstance.message = 'Your Request has been successfully added.'
    this.router.navigate(['/panel','requests'])
  }


  public openDialogAddSurveySuccessfully(){
    this.modalService.dismissAll()
    this.modalCtrl.dismiss(null,'cancel')
    const modalRef = this.modalService.open(CreateRequestSuccessModalComponent,{centered:true})
    modalRef.componentInstance.message = 'Your Survey has been successfully added.'
    // this.router.navigate(['/panel','requests'])
  }



}
